import React, { Component } from "react";
import { Link } from "react-router-dom";
import Lang from "./Lang/Lang";
import WinHeightControl from "../../../../tools/WinHeightControl/WinHeightControl";

import logos from "../../../../tools/Logos/Logos";

import classes from "./Work.module.css";

class Work extends Component {
  state = {
    langs: [
      {
        name: "React JS",
        color: "#60dafb",
        bgcolor: "#222222",
        logo: logos.React,

        topics: [
          {
            title: "Barbershop App",
            url: "https://hairbyjoni.netlify.com",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422536/portfolio/hairbyjoni.png"
          },
          {
            title: "Chat App",
            url: "https://chatme.netlify.com",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422536/portfolio/chat.png"
          },
          {
            title: "Progress App",
            url: "https://progress-app.netlify.com",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554442250/portfolio/progress500px.png"
          }
        ]
      },
      {
        name: "NodeJS",
        color: "#83CD29",
        bgcolor: "#222222",
        logo: logos.Node,

        topics: [
          {
            title: "RESTful API",
            url: "https://maljuburi-node-api.herokuapp.com",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422523/portfolio/restful_Node.png"
          }
        ]
      },
      {
        name: "JavaScript",
        color: "#f5dc39",
        bgcolor: "#222222",
        logo: logos.JavaScript,
        topics: [
          {
            title: "IMAGE SLIDER",
            url: "https://maljuburi.github.io/JS-slider",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422503/portfolio/ImgSlider.png"
          },
          {
            title: "CANVAS ANIMATION",
            url: "https://maljuburi.github.io/JS-canvas",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422514/portfolio/jsCanvas.gif"
          },
          {
            title: "JAVASCRIPT EVENTS",
            url: "https://maljuburi.github.io/JS-events",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422502/portfolio/JSevents.png"
          }
        ]
      },
      // {
      //   name: "WordPress",
      //   color: "#198bbe",
      //   bgcolor: "#222222",
      //   logo: logos.WordPress,

      //   topics: [
      //     {
      //       title: "Custom WordPress Theme",
      //       url: "http://138.197.202.13",
      //       img: loe
      //     }
      //   ]
      // },
      // {
      //   name: "Laravel",
      //   color: "#FD4F31",
      //   bgcolor: "#222222",
      //   logo: logos.Laravel,

      //   topics: [
      //     {
      //       title: "Blog App",
      //       url: "http://159.65.99.17",
      //       img: laravel_blog
      //     }
      //   ]
      // },

      {
        name: "jQuery",
        color: "#0768ac",
        bgcolor: "#222222",
        logo: logos.jQuery,
        topics: [
          {
            title: "RESPONSIVE NAV",
            url: "https://maljuburi.github.io/jQuery-responsive-navbar",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422515/portfolio/navbarImg.png"
          }
        ]
      },
      {
        name: "CSS3",
        color: "#33a9dc",
        bgcolor: "#222222",
        logo: logos.CSS3,
        topics: [
          {
            title: "FLEX",
            url: "https://maljuburi.github.io/flex",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422472/portfolio/flexImg.png"
          }
        ]
      },
      {
        name: "HTML5",
        color: "#f16529",
        bgcolor: "#222222",
        logo: logos.HTML5,
        topics: [
          {
            title: "HTML5 INPUT TYPES",
            url: "https://maljuburi.github.io/html5-input-types",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422472/portfolio/htmlInputTypes.png"
          },
          {
            title: "SVG loading",
            url: "https://codepen.io/maljuburi/pen/bOYWam",
            img:
              "https://res.cloudinary.com/maljuburi/image/upload/v1554422473/portfolio/svgLoading.gif"
          }
        ]
      }
    ]
  };

  componentDidMount() {
    const WHC = new WinHeightControl();
    WHC.checkPage();
  }

  renderLang = () => {
    const langs = this.state.langs.map((lang, i) => (
      <Lang
        key={i}
        name={lang.name}
        color={lang.color}
        bgcolor={lang.bgcolor}
        topics={lang.topics}
        logo={lang.logo}
        clicked={this.handleClick}
      />
    ));
    return langs;
  };

  handleClick = e => {
    const url = e.target.dataset.url;
    window.open(url, "_blank");
  };

  render() {
    return (
      <div className={classes.container}>
        <h3>
          <Link to="/" className={classes.homeLink}>
            <i className="fa fa-arrow-left" style={{ marginRight: "5px" }} />
            Back to Profile
          </Link>
        </h3>

        {this.renderLang()}
      </div>
    );
  }
}

export default Work;
