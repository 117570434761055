import React from "react";
import Card from "./Card/Card";
import classes from "./Lang.module.css";

const Lang = props => {
  return (
    <div className={classes.container}>
      <h1
        className={classes.header}
        style={{ backgroundColor: props.bgcolor, color: props.color }}
      >
        {props.logo ? <img src={props.logo} alt="logo" /> : null}
        {props.name}
      </h1>
      <div className={classes.body}>
        {props.topics.map((topic, i) => (
          <Card
            key={i}
            title={topic.title}
            img={topic.img}
            url={topic.url}
            clicked={props.clicked}
          />
        ))}
      </div>
    </div>
  );
};

export default Lang;
