import HTML5 from "../../assets/HTML5CSS3/html5.svg";
import CSS3 from "../../assets/HTML5CSS3/css3.svg";
import JavaScript from "../../assets/JavaScript/javascript.svg";
import jQuery from "../../assets/jQuery/jquery.svg";
import React from "../../assets/React/react.svg";
import PHP from "../../assets/PHP/php.svg";
import Laravel from "../../assets/Laravel/laravel.svg";
import WordPress from "../../assets/Wordpress/wordpress.svg";
import Node from "../../assets/Node/Node.svg";

const logos = {
  HTML5,
  CSS3,
  JavaScript,
  jQuery,
  React,
  PHP,
  Laravel,
  WordPress,
  Node
};

export default logos;
