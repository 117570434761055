import React, { Component } from "react";
import { Link } from "react-router-dom";
import WinHeightControl from "../../../../tools/WinHeightControl/WinHeightControl";

import restful from "../../../../assets/restful.svg";
import auth from "../../../../assets/authentication.svg";
import routingSVG from "../../../../assets/routing.svg";
import myImage from "../../../../assets/ProfileImg.png";
import github from "../../../../assets/github.svg";

import classes from "./Home.module.css";

class Home extends Component {
  componentDidMount() {
    const WHC = new WinHeightControl();
    WHC.checkPage();
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.portfolio}>
          <p className={classes.codewars}>
            <img
              src="https://www.codewars.com/users/maljuburi/badges/small"
              alt="my-codewars"
            />
          </p>
          <img className={classes.myImage} src={myImage} alt="Me" />
          <h2 className={classes.slag}>
            Hello, I'm <span className={classes.highlight}>Muhammad.</span>
            <br />
          </h2>
          <h3 className={classes.typingClass}>Full Stack Software Engineer</h3>

          <p className={classes.desc}>
            Thank you for taking the time to look at my portfolio. I am a self
            driven learner, designer and developer. I speak many computer
            languages, but the language I speak the most is JavaScript.
          </p>

          <div className={classes.cardsContainer}>
            <div className={classes.skillCard}>
              <img src={restful} alt="restfull" />
              <p>CRUD / RESTful API</p>
            </div>
            <div className={classes.skillCard}>
              <img width="90" src={auth} alt="authentication" />
              <p>Authentication / Authorization</p>
            </div>
            <div className={classes.skillCard}>
              <img width="200" src={routingSVG} alt="routing svg" />
              <p>Routing / SPA</p>
            </div>
          </div>

          <h3>
            <Link to="/work" className={classes.portfolioLink}>
              View Portfolio
            </Link>
          </h3>

          <a
            href="https://github.com/maljuburi"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.github}
          >
            <img src={github} className={classes.githubSVG} alt="github link" />
            Github Repositories
          </a>
          <p className={classes.gmail}>
            <span className={`fa fa-envelope ${classes.gmailSVG}`} />
            maljuburi89@gmail.com
          </p>
        </div>
      </div>
    );
  }
}

export default Home;
