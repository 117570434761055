import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Work from "./Pages/Work/Work";

class Layout extends Component {
  state = {
    links: [
      { id: 1, page: "/Home" },
      // { id: 2, page: "/Skills" },
      { id: 3, page: "/Work" }
    ]
  };

  render() {
    return (
      <React.Fragment>
        {/* <Nav links={this.state.links} /> */}

        <Switch>
          <Route path="/work" component={Work} />
          {/* <Route path="/Skills" component={Skills} /> */}
          <Route path="/" component={Home} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Layout;
