class WinHeightControl {
  wh = window.innerHeight;
  html = document.getElementsByTagName("html");
  body = document.getElementsByTagName("body");
  root = document.getElementById("root");
  checkPage = () => {
    if (this.wh <= this.body[0].scrollHeight) {
      this.html[0].style.height = "auto";
      this.body[0].style.height = "auto";
    } else {
      this.html[0].style.height = "100%";
      this.body[0].style.height = "100%";
    }
  };
}

export default WinHeightControl;
