import React, { Component } from "react";
import Layout from "./components/Layout/Layout";
import "./App.css";

class App extends Component {
  render() {
    // console.log(process.env.REACT_APP_TEST);
    return <Layout />;
  }
}

export default App;
