import React, { Component } from "react";
import classes from "./Card.module.css";

class Card extends Component {
  render() {
    return (
      <div className={classes.col3}>
        <img
          onClick={this.props.clicked}
          src={this.props.img}
          className={classes.img}
          data-url={this.props.url}
          alt=""
        />
        <p className={classes.cardDesc}>{this.props.title}</p>
      </div>
    );
  }
}

export default Card;
